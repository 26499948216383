<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <!-- 容器 -->
      <div class="weui-tab">
        <div class="weui-tab__bd">
          <div
            id="tab1"
            class="weui-tab__bd-item weui-tab__bd-item--active"
            data-status=""
          >
            <div class="weui-cells weui-cells_form public-areas">
              <div class="weui-cell">
                <div class="weui-cell__bd">
                  <i
                    class="icon icon-public-bill widtdraw-coin-title text-blue"
                  ></i
                  >&nbsp;提现
                  <span class="remark">&nbsp;&nbsp;（100金币=1.00元）</span>
                </div>
              </div>
              <div class="weui-cell">
                <div class="weui-cell__bd">
                  <div class="weui-flex">
                    <div class="weui-flex__item text-center">
                      <div>
                        <span class="text-blue"
                          >{{totalScore}}
                          <i class="icon icon-public-coins coin text-blue"></i
                        ></span>
                      </div>
                      <div class="remark">累计收益</div>
                    </div>
                    <div class="weui-flex__item text-center">
                      <div>
                        <span class="text-blue"
                          >{{availableScore + frozenScore}}
                          <i class="icon icon-public-coins coin text-blue"></i
                        ></span>
                      </div>
                      <div class="remark">账户余额</div>
                    </div>
                    <div class="weui-flex__item text-center">
                      <div>
                        <span class="text-green"
                          >{{availableScore}}
                          <i class="icon icon-public-coins coin text-green"></i
                        ></span>
                      </div>
                      <div class="remark">
                        当前可提现
                        <a
                          href="javascript:;"
                          @click="showTip"
                          class="show-freeze-remark"
                          ><i
                            class="icon icon-public-help"
                            data-type="zhibaojin"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="weui-cells__title"></div>
            <div class="weui-cells weui-cells_form public-areas">
              <div class="weui-cell">
                <div class="weui-cell__bd">
                  <div class="remark">问：为何有部分余额暂时不能提现？</div>
                </div>
              </div>
              <div class="weui-cell">
                <div class="weui-cell__bd">
                  <div class="remark">
                    答：因部分用户答题过程中乱答乱写...&nbsp;&nbsp;<a
                      href="javascript:;"
                      @click="showTip"
                      class="show-freeze-remark text-blue"
                      >查看详情</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="weui-cells__title">支付宝现金</div>
            <div class="weui-cells weui-cells_form public-areas">
              <div class="weui-cell v-align-top withdraw-form-item">
                <div class="weui-cell__bd">
                  <div class="weui-flex">
                    <div class="weui-flex__item text-center" @click="willChangeAmount(10)" >
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 10}]"
                        data-id="1zeqze4m"
                      >
                        0.1元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/pay-logo-alipay.jpg"
                        />
                      </span>
                    </div>

                    <div class="weui-flex__item text-center" @click="willChangeAmount(20)">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 20}]"
                        data-id="q6ex4jnz"
                      >
                        0.2元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/pay-logo-alipay.jpg"
                        />
                      </span>
                    </div>

                    <div class="weui-flex__item text-center" @click="willChangeAmount(30)">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 30}]"
                        data-id="w4ey5j5z"
                      >
                        0.3元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/pay-logo-alipay.jpg"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="weui-flex">
                    <div class="weui-flex__item text-center" @click="willChangeAmount(100)">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 100}]"
                        data-id="mgjkyep2"
                      >
                        1元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/pay-logo-alipay.jpg"
                        />
                      </span>
                    </div>

                    <div class="weui-flex__item text-center" @click="willChangeAmount(200)">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 200}]"
                        data-id="25jp2r97"
                      >
                        2元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/pay-logo-alipay.jpg"
                        />
                      </span>
                    </div>

                    <div class="weui-flex__item text-center" @click="willChangeAmount(500)">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 500}]"
                        data-id="kgj7qrdz"
                      >
                        5元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/pay-logo-alipay.jpg"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="weui-flex">
                    <div class="weui-flex__item text-center" @click="willChangeAmount(1000)">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 1000}]"
                        data-id="35ewgrxv"
                      >
                        10元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/pay-logo-alipay.jpg"
                        />
                      </span>
                    </div>
                    <div class="weui-flex__item text-center" @click="willChangeAmount(5000)">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 5000}]"
                        data-id="35ewgrxv"
                      >
                        50元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/pay-logo-alipay.jpg"
                        />
                      </span>
                    </div>
                    <div class="weui-flex__item text-center" @click="willChangeAmount(10000)">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 10000}]"
                        data-id="35ewgrxv"
                      >
                        100元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/pay-logo-alipay.jpg"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="weui-cells__title">微信红包</div>
            <div class="weui-cells weui-cells_form public-areas">
              <div class="weui-cell v-align-top withdraw-form-item">
                <div class="weui-cell__bd">
                  <div class="weui-flex">
                    <div class="weui-flex__item text-center">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 30}]"
                        data-id="k8e2we01"
                      >
                        0.3元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/weixin-hongbao.png"
                        />
                      </span>
                    </div>

                    <div class="weui-flex__item text-center">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 100}]"
                        data-id="87jn9rk9"
                      >
                        1元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/weixin-hongbao.png"
                        />
                      </span>
                    </div>

                    <div class="weui-flex__item text-center">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 500}]"
                        data-id="48j3gr62"
                      >
                        5元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/weixin-hongbao.png"
                        />
                      </span>
                    </div>
                  </div>
                  <div class="weui-flex">
                    <div class="weui-flex__item text-center">
                      <span
                        :class="['pay-type-item', 'pay-type-item-js', 'text-center', {'actived' : amount === 1000}]"
                        data-id="wzj42rmq"
                      >
                        10元<i class="icon icon-public-xuanze"></i>
                        <br />
                        <img
                          class="pay-logo"
                          alt=""
                          src="../assets/weixin-hongbao.png"
                        />
                      </span>
                    </div>

                    <div class="weui-flex__item text-center"></div>
                    <div class="weui-flex__item text-center"></div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="weui-cells margin-top-0">
              <div class="weui-cell">
                <div class="weui-cell__bd">
                  <a
                    href="javascript:;"
                    class="weui-btn weui-btn_primary btn-balance-withdraw"
                    @click="willWithdraw"
                    >立即提现</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>

<script>
import { getUserInfo, scoreRedeem } from '../api/index'
import tabbar from '../components/tabbar/index.vue'
import wxauth from '../mixin/wxauth'
export default {
  mixins: [wxauth],
  name: "withdraw",
  components: {
    tabbar
  },
  data () {
    return {
      totalScore: 0,
      availableScore: 0,
      frozenScore: 0,
      amount:  0, // 体现金额
      // aliAmount: 0, // 支付宝金额
      channel: 'ALP', // 渠道
    }
  },
  created () {
    this.doGetUserInfo()
  },
  mounted () {
    // this.doScoreRedeem()
  },
  methods: {
    // 提交用户信息
    async doGetUserInfo () {
      let params = {
        param: {}
      }
      let res = {}
      try {
        res = await getUserInfo(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          this.totalScore = result.totalScore
          this.availableScore = result.availableScore
          this.frozenScore = result.frozenScore
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 提现接口
    async doScoreRedeem () {
      const amount = Number((this.amount / 100).toFixed(2))
      let params = {
        param: {
          amount: amount,
          paymentChannel: 'ALP'
        }
      }
      let res = {}
      try {
        res = await scoreRedeem(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          // this.$toast.hide()
          this.$toast.info('提现申请已提交')
          // this.doGetUserInfo()
          this.availableScore = this.availableScore - this.amount
          this.amount = 0
        } else {
          this.$toast.hide()
          this.$toast.info(res.message)
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    willChangeAmount (amount) {
      if (amount > this.availableScore) {
        this.$toast.info('可提现额度不足，请选择其他提现额度')
        return
      }
      this.amount = amount
    },
    // 提现按钮点击
    willWithdraw () {
      if (!this.amount) {
        this.$toast.info('请选择提现额度')
        return
      }
      this.doScoreRedeem()
    },
    showTip() {
      let content = "";
      content += '<div class="text-left" >';
      content +=
        '<p class="margin-bottom-10">因部分用户答题过程中乱答乱写，严重影响数据质量。为了保证作答有效，部分问卷作答成功获得奖励后，将暂时冻结部分成功奖励等待质量审核，等待审核期间这部分金额不可提现。具体规则如下：</p>';
      content +=
        '<p class="margin-bottom-10">1、如果审核不合格，将从账户余额中扣除本次作答的全额奖励</p>';
      content +=
        '<p class="margin-bottom-10">2、如果审核通过，冻结的金币将立即取消冻结、恢复为可提现状态</p><br>';
      content +=
        '<p class="margin-bottom-10 text-red">温馨提示：为了大家的利益不受损失，请大家认真作答、切勿乱答乱写哦~</p>';

      content += "</div>";
      this.$dialog.show({
        title: "为何有部分余额暂时不能提现",
        content: content,
        showCancel: false,
        okText: "确定",
      });
    },
  },
};
</script>

<style lang="css" scoped>
.public-container{
  margin: unset;
}
.weui-btn.btn-balance-withdraw{
  padding: unset;
}
.weui-flex__item .pay-type-item.actived{
  box-shadow: 0 0 2px 2px blueviolet;
}
</style>
